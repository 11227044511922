import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Table } from '@f2w/data-table/table-types';
import { ActionsDropdown } from '@f2w/data-table/components/ActionsDropdown';
import generateRoute, { generate } from 'Services/Router';
import { DeleteIcon } from 'Components/App/Icons';
import { translate } from 'Services/Translator';
import { RentalObjectCard } from 'Components/App/card';
import { Badge, Dropdown, SafeAnchor } from 'Components/Atoms';
import { ProcessState } from 'Pages/Process/enums';
import { DateType } from '@f2w/form';
import { ActiveView, useObjectViewModalContext } from 'Components/ObjectViewModal';
import { hasFeature } from "Services/FeatureFlagManager";
import { withParams } from "@f2w/utils";
const renderStateBadge = (state) => {
    const label = translate(`process.state.${state}`);
    switch (state) {
        case ProcessState.CREATED: {
            return _jsx(Badge, { "$color": 'primary', children: label });
        }
        case ProcessState.DOCUMENTS_SENT: {
            return _jsx(Badge, { "$color": 'warning', children: label });
        }
        case ProcessState.DOCUMENTS_RECEIVED: {
            return _jsx(Badge, { "$color": 'primaryOutline', children: label });
        }
        case ProcessState.COMPLETED: {
            return _jsx(Badge, { "$color": 'success', children: label });
        }
        case ProcessState.DRAFT: {
            return _jsx(Badge, { "$color": 'secondary', children: label });
        }
        case ProcessState.CANCELED: {
            return _jsx(Badge, { "$color": 'danger', children: label });
        }
    }
};
const model = Table.factory()(() => ({
    tableId: 'Process',
    RowActions: ({ row: { original: { id, contractId } }, refresh, onDeleteClicked }) => (_jsx(ActionsDropdown, { id: id, quickActions: hasFeature('TEMPLATES_V2') ? [{
                icon: 'eye',
                title: translate('process.view'),
                href: generateRoute('contracts.overview', { contractId })
            }] : [], children: _jsxs(ActionsDropdown.Link, { onClick: () => onDeleteClicked(id, refresh), children: [_jsx(DeleteIcon, {}), " ", _jsx("span", { children: translate('btn.delete') })] }) })),
    Actions: () => (_jsxs(Dropdown, { children: [_jsx(Dropdown.Toggle, { id: "new-process", children: translate('btn.new') }), _jsxs(Dropdown.Menu, { children: [_jsx(Dropdown.Item, { href: generateRoute('onboard'), children: translate('contract.tenant.onboard') }), _jsx(Dropdown.Item, { href: withParams("/app/wizard/contract-notice/undefined", {
                            referer: generate('process.overview')
                        }), children: translate('contract.tenant.offboard') })] })] })),
    useSortBy: {},
    useGlobalFilter: {},
    usePagination: {},
    columns: {
        $actions: {
            sticky: 'left'
        },
        objectData: {
            Header: _jsx("div", { style: { overflowWrap: 'break-word' }, children: translate('creditor.propertyOrRentalObject') }),
            useSortBy: {},
            Cell: ({ row: { original: { property, rentalObject } } }) => {
                const { openViewModalObj, activeView } = useObjectViewModalContext();
                return _jsxs(_Fragment, { children: [rentalObject && (_jsx(RentalObjectCard, { rentalObjectName: rentalObject.name, rentalObjectId: rentalObject.id, buildingName: rentalObject.buildingName })), (property && !rentalObject) && (_jsx("div", { children: _jsx(SafeAnchor, { href: '#', onClick: (event => {
                                    event.preventDefault();
                                    openViewModalObj({
                                        id: property.id,
                                        view: ActiveView.PROPERTY,
                                    });
                                }), children: property.name }) })), !property && !rentalObject && translate('notAvailable')] });
            }
        },
        state: {
            Header: translate('process.state'),
            Cell: ({ value }) => hasFeature('TEMPLATES_V2')
                ? renderStateBadge(ProcessState.COMPLETED)
                : renderStateBadge(value)
        },
        type: {
            Header: translate('process.type'),
            Cell: ({ value }) => value
                ? _jsx(Badge, { "$color": 'primaryOutline', children: translate(`process.${value}`) })
                : translate('notAvailable')
        },
        starts: {
            Header: translate('process.started'),
            useSortBy: {},
            Cell: ({ value }) => DateType.format(value)
        },
        changeAsOf: {
            Header: translate('process.changeAsOf'),
            useSortBy: {},
            Cell: ({ value }) => DateType.format(value)
        }
    }
}));
export default model;
