import { jsx as _jsx } from "react/jsx-runtime";
import { useMemo } from 'react';
import { BaseFormTypeOld, SelectType } from '@f2w/form-new';
import Portfolio from 'Models/Portfolio';
import { translate } from 'Services/App';
import services from './contractCreateServices';
import { getRoot } from './globals';
import { RentalObjectStepTemplate } from './RentalObjectStepTemplate';
import createServices from './contractCreateServices';
const getStep = (v) => getRoot(v).children.rentalObjectStep;
const getStepData = (v) => getRoot(v).children.rentalObjectStep.value;
export class RentalObjectStep extends BaseFormTypeOld {
    async loadData(contractId, date) {
        try {
            if (contractId) {
                const handler = this.specs.options.loadData ?? services.getStepNotice;
                const data = await handler(contractId, date);
                if (data)
                    return data;
            }
        }
        catch (error) {
            console.error('getRentModificationStepDate', error);
        }
    }
    buildFields() {
        const portfolio = Portfolio.instance();
        return {
            propertyId: SelectType.number({
                label: translate('property'),
                required: true,
                choices: () => useMemo(() => portfolio.getProperties().map(property => ({
                    label: property.name,
                    value: property.id,
                })), []),
                onChange: async (v) => {
                    const _step = getStep(v);
                    _step.children.buildingId.updateValue(null, true);
                    const contracts = await createServices.getMainContracts(v.value);
                    const mainContractOptions = {
                        choices: contracts.map(data => ({
                            value: data.id, label: data.label,
                        })),
                    };
                    _step.children.mainContractId.resetOptions(mainContractOptions);
                },
            }),
            buildingId: SelectType.number({
                label: translate('building.title'),
                required: true,
                isDisabled: (v) => {
                    const isDisabled = getStepData(v).propertyId;
                    return !isDisabled;
                },
                choices: (v) => {
                    const id = getStepData(v).propertyId;
                    return useMemo(() => portfolio.getBuildingsByPropertyId(id).map(data => ({
                        value: data.id, label: data.name,
                    })), [id]);
                },
                onChange: (v) => {
                    getStep(v).children.rentalObjectId.updateValue(null, true);
                },
            }),
            rentalObjectId: SelectType.number({
                label: translate('rentalObject'),
                required: true,
                isDisabled: (v) => {
                    const isDisabled = getStepData(v).buildingId;
                    return !isDisabled;
                },
                choices: (v) => {
                    const id = getStepData(v).buildingId;
                    return useMemo(() => portfolio.getRentalObjectsByBuildingId(id).map(data => ({
                        value: data.id, label: data.name,
                    })), [id]);
                },
                onChange: async (v, { initial }) => {
                    getStep(v).children.mainContractId.updateValue(null, true);
                },
            }),
            mainContractId: SelectType.number({
                label: translate('contract'),
                required: false,
                choices: [],
                isDisabled: (v) => {
                    const isDisabled = getStepData(v).rentalObjectId;
                    return !isDisabled;
                },
                onChange: (v) => {
                    const _step = getStep(v);
                    _step.updateValue({ selectedContract: portfolio.getContractById(v.value) });
                },
            }),
        };
    }
    _renderWidget(props) {
        return _jsx(RentalObjectStepTemplate, { ...props });
    }
}
