import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { createBrowserRouter, Link, Outlet, RouterProvider } from 'react-router-dom';
import { wizardRoute } from '@f2w/view';
import { Div } from 'Atoms/Layout';
import { useContractModificationWizard } from 'Pages/Contract/hooks/useContractModificationWizard';
import { isDevEnv } from '@f2w/utils';
import { AppProvider } from './provider';
import { useContractNoticeWizard } from 'Pages/Contract/wizard/NoticeWizard';
import { useContractCreateWizard } from 'Pages/Contract/wizard/contract-create/useContractCreateWizard';
const NothingHere = ({ title = 'Nothing found' }) => {
    return _jsxs(Div, { p: 40, children: [_jsx("h3", { children: title }), _jsx(Outlet, {}), _jsx(Link, { to: '/', children: "Go back home" })] });
};
const Layout = () => {
    return _jsx(AppProvider, { children: _jsx(Outlet, {}) });
};
export const routes = [
    {
        element: _jsx(Layout, {}),
        children: [
            {
                path: '/app/wizard',
                children: [
                    wizardRoute(useContractModificationWizard()),
                    wizardRoute(useContractNoticeWizard()),
                    wizardRoute(useContractCreateWizard()),
                    {
                        path: '*',
                        element: _jsx(NothingHere, { title: 'App' }),
                    },
                ],
            },
            {
                path: '*',
                element: _jsx(NothingHere, { title: 'Root' }),
            }
        ],
    },
];
const router = createBrowserRouter(routes, {});
export const AppRouter = () => {
    if (isDevEnv())
        console.log('-- AppRouter -- ', routes);
    return (_jsx(RouterProvider, { router: router, fallbackElement: _jsx(NothingHere, { title: 'Fallback' }) }));
};
