export var SaveCalled;
(function (SaveCalled) {
    SaveCalled[SaveCalled["REFRESH_ACCOUNTS"] = 0] = "REFRESH_ACCOUNTS";
    SaveCalled[SaveCalled["REFRESH_FLAT_FEES"] = 1] = "REFRESH_FLAT_FEES";
    SaveCalled[SaveCalled["SAVE_FLAT_FEES"] = 2] = "SAVE_FLAT_FEES";
    SaveCalled[SaveCalled["SAVE_DISTRIBUTIONS"] = 3] = "SAVE_DISTRIBUTIONS";
    SaveCalled[SaveCalled["SAVE_FIXED_COST"] = 4] = "SAVE_FIXED_COST";
    SaveCalled[SaveCalled["BILL_CALCULATION"] = 5] = "BILL_CALCULATION";
    SaveCalled[SaveCalled["VKA_IMPORT"] = 6] = "VKA_IMPORT";
})(SaveCalled || (SaveCalled = {}));
