import { generate } from 'Services/Router';
import client from 'Utils/client';
import { DateValueType } from 'fairwalter/packages/form-new/src';
import { sleep } from 'Utils/delay';
import moment from 'moment';
export var ContractCreate;
(function (ContractCreate) {
    ContractCreate.getMainContracts = async (propertyId) => {
        return [];
    };
    ContractCreate.getStepNotice = async (contractId, date) => {
        await sleep(500);
        console.log('getStepNotice', { contractId, date });
        return {
            minimumDate: DateValueType.formatJson(moment().subtract(10, 'days')),
            nextNoticeDate: DateValueType.formatJson(new Date()),
            futureRentChangeDate: DateValueType.formatJson(moment().add(11, 'days')),
            subContracts: [
                {
                    id: 1,
                    name: 'Variant 1',
                    nextNoticeDate: (new Date()).toString(),
                },
                {
                    id: 2,
                    name: 'Variant 2',
                    nextNoticeDate: (new Date()).toString(),
                    futureRentChangeDate: (moment().subtract(1, 'days')).toString()
                },
                {
                    id: 3,
                    name: 'Variant 3',
                    nextNoticeDate: (new Date()).toString(),
                    futureRentChangeDate: (moment().add(1, 'days')).toString()
                },
                {
                    id: 4,
                    name: 'Variant 4',
                    nextNoticeDate: (new Date()).toString(),
                    futureRentChangeDate: (new Date()).toString(),
                },
            ],
        };
    };
    ContractCreate.save = (contractId, data) => client
        .patch(generate('api.contracts.terminate', { contract: contractId }), data)
        .then((response) => { });
})(ContractCreate || (ContractCreate = {}));
export default ContractCreate;
