import { createWizardType } from '@f2w/view';
import Portfolio from 'Models/Portfolio';
import contractServices from 'Pages/Contract/services/contractServices';
import { translate } from 'Services/App';
import { generate } from 'Services/Router';
import { ContractModificationWizardType } from '../types/ContractModificationWizardType';
import { isNumLike, toNum } from '@f2w/utils';
export const useContractModificationWizard = () => {
    const getScopeFromContract = (contractId) => {
        const portfolio = Portfolio.instance();
        const contract = isNumLike(contractId) && portfolio.getContractById(toNum(contractId));
        if (contract) {
            return {
                propertyId: contract.propertyId,
                buildingId: contract.buildingId,
                rentalObjectId: contract.rentalObjectId,
                contractId: contract.id,
            };
        }
        return portfolio.getScopeData();
    };
    const save = async (data) => {
        const saveData = {
            validFrom: data.selectionStep.validFrom,
            adjustmentAmount: data.rentStep.adjustmentAmount,
            hasSubsidies: data.rentStep.hasSubsidies,
            reason: data.rentStep.reason,
            reservation: data.rentStep.reservation,
            referenceInterestRateAdjustment: {
                date: data.rentStep.rentPotentialDate,
                referenceRate: data.rentStep.useChangeOfReferenceInterest,
                inflation: data.rentStep.useInflationIndexChange,
                costIncrease: data.rentStep.useCostIncreaseChange,
            },
            roundingMode: data.rentStep.roundDifference,
        };
        return contractServices.createRentModification(data.selectionStep.contractId, saveData);
    };
    const c = createWizardType({
        id: 'contractModification',
        path: 'contract-modification/:id',
        onClose: ({ navigate }) => {
            window.location.href = generate('contracts.overview');
        },
        title: () => translate('contractModification.wizardTitle'),
        labels: {
            save: translate('contractModification.saveAction')
        },
        load: async ({ params }) => {
            const stepData = {
                selectionStep: {
                    settings: {
                        contracts: [],
                    },
                    ...getScopeFromContract(params.id)
                },
            };
            return {
                id: params.id,
                stepData
            };
        },
        save: async (values, stepApi) => {
            const props = await save(values);
            const contractId = stepApi.valueType.children.selectionStep.value.contractId;
            window.location.href = generate('contracts.overview', { contractId });
            return props;
        },
        steps: {
            selectionStep: {
                title: () => translate('contract.selection'),
                type: () => new ContractModificationWizardType.SelectionStep({ hideLabel: true }),
            },
            rentStep: {
                title: () => translate('rentModification.title'),
                type: () => new ContractModificationWizardType.RentStep({ hideLabel: true }),
            },
            summaryStep: {
                title: () => translate('rentModification.summary.title'),
                type: () => new ContractModificationWizardType.SummaryStep({ hideLabel: true }),
            },
        },
    })({});
    return c;
};
