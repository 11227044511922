import { jsx as _jsx } from "react/jsx-runtime";
import { BaseFormTypeOld, StringType } from '@f2w/form-new';
import { PersonsStepTemplate } from './PersonsStepTemplate';
import { translate } from 'Services/App';
export class PersonsStep extends BaseFormTypeOld {
    buildFields() {
        return {
            name: new StringType({
                required: true,
                label: () => translate('contract'),
            }),
        };
    }
    _renderWidget(props) {
        return _jsx(PersonsStepTemplate, { ...props });
    }
}
