import { createWizardType } from '@f2w/view';
import Portfolio from 'Models/Portfolio';
import { translate } from 'Services/App';
import { isNumLike, toNum } from '@f2w/utils';
import createServices from './components/contractCreateServices';
import * as CreateWizard from './components';
import { generate } from 'Services/Router';
export const useContractCreateWizard = () => {
    const getScopeFromContract = (rentalObjectId) => {
        const portfolio = Portfolio.instance();
        const rentalObject = isNumLike(rentalObjectId) && portfolio.getRentalObjectById(toNum(rentalObjectId));
        if (rentalObject) {
            return {
                propertyId: portfolio.getPropertyByRentalObjectId(rentalObject.id).id,
                buildingId: rentalObject.buildingId,
                rentalObjectId: rentalObject.id,
            };
        }
        return portfolio.getScopeData();
    };
    const save = async (valueType) => {
        const root = CreateWizard.getRoot(valueType);
        const data = root.getResolvedValue();
        console.log('contract create', data);
        return;
        const saveData = {
            dateOfNotice: data.noticeStep.dateOfNotice,
            noticePer: data.noticeStep.noticePer,
            terminatedBy: data.noticeStep.noticeBy,
            selectedSubContractIds: []
        };
        return createServices.save(data.noticeStep.contractId, saveData);
    };
    const cfg = createWizardType({
        id: 'contractCreate',
        path: 'contract-create/:id',
        labels: {
            save: translate('contractNotice.saveAction')
        },
        title: () => translate('contractNotice.wizardTitle'),
        load: async ({ params }) => {
            const stepData = {
                rentalObjectStep: {
                    setting: {},
                    ...getScopeFromContract(params.id)
                },
                personsStep: {}
            };
            return {
                id: params.id,
                stepData
            };
        },
        save: async (values, stepApi) => {
            const props = await save(stepApi.valueType);
            const contractId = stepApi.valueType.children.noticeStep.value.contractId;
            window.location.href = generate('contracts.overview', { contractId });
            return props;
        },
        onClose: ({ navigate }) => {
            window.location.href = generate('contracts.overview');
        },
        steps: {
            rentalObjectStep: {
                title: () => translate('contractCreate.rentalObjectStep.title'),
                type: () => new CreateWizard.RentalObjectStep({ hideLabel: true }),
            },
            personsStep: {
                title: () => translate('contractCreate.personsStep.title'),
                type: () => new CreateWizard.PersonsStep({ hideLabel: true }),
            },
            summaryStep: {
                title: () => translate('contractCreate.summaryStep.title'),
                type: () => new CreateWizard.SummaryStep({ hideLabel: true }),
            },
        },
    })({});
    return cfg;
};
