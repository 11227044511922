import { jsx as _jsx } from "react/jsx-runtime";
import { FormError } from '@f2w/form';
import { GeneralTab } from '../components/GeneralTab';
import { AncillaryExpensesTab } from '../components/AncillaryExpensesTab';
import { PersonsTab } from '../components/PersonsTab';
import { DocumentsTab } from '../components/DocumentsTab';
import { UploadsTab } from '../components/UploadsTab';
import { ContractType } from '../../../types/ContractType';
import { translate } from 'Services/Translator';
import { AncillaryExpenseType } from 'Pages/AncillaryExpense/enums';
import { useViewType } from '@f2w/view';
import { ContractTemplate } from '../components/ContractTemplate';
import moment from 'moment';
import { editContractDepositData } from 'Components/ObjectViewModal/services/objectViewModalServices';
import { DepositTab } from 'Pages/Contract/components/DetailView/components/DepositTab';
import { QuickschildIntegrationApi } from 'Pages/Quickschild/services/QuickschildIntegrationService';
export const useContractView = ({ getContractDetails: loadData, editContractGeneralData, editContractAncillaryExpensesData, editContractPersonsData, setContractData, revertContractTermination }) => useViewType(() => new ContractType(), {
    id: 'contract-main',
    defaultState: {
        leftActiveTab: 'general',
        revertContractTermination,
        selectedSubContracts: []
    },
    DefaultTemplate: props => _jsx(ContractTemplate, { ...props }),
    load: async (id) => {
        const data = await loadData(id);
        data.quickschild = await QuickschildIntegrationApi.getIntegration();
        setContractData({
            contractStarts: data.startDate ? moment(data.startDate).format('DD.MM.YYYY') : undefined,
            primaryContractPartyName: data
                .contractParties
                .find(contractParty => contractParty.isPrimary)
                ?.fullName
        });
        data.fixed = [];
        data.floating = [];
        data.ancillaryExpenses.forEach((ancillaryExpense) => {
            if (ancillaryExpense.collectionType === AncillaryExpenseType.FIXED) {
                data.fixed.push({
                    id: ancillaryExpense.id,
                    accountId: ancillaryExpense.accountId,
                    flatValue: ancillaryExpense.value,
                });
            }
            else {
                data.floating.push({
                    id: ancillaryExpense.id,
                    accountId: ancillaryExpense.accountId,
                });
            }
        });
        data.mainContractId = data.mainContract?.id;
        data.depositBankAccountId = data.depositBankAccount?.id;
        return data;
    },
    saveError: (error, view) => {
        const { data, status } = error?.response ?? {};
        if ([400, 422].includes(status) || data?.message || data?.errors) {
            if (data?.errors) {
                let errors = [];
                for (let key in data.errors) {
                    if (data.errors[key] instanceof Array) {
                        for (const error of data.errors[key]) {
                            errors.push(error);
                        }
                    }
                    else {
                        errors.push(data.errors[key]);
                    }
                }
                return new FormError(errors.join(' | '));
            }
            return new FormError(data.message);
        }
        return error;
    },
}, () => {
    const sections = {
        general: {
            name: translate('contract.details.general'),
            Content: (props) => _jsx(GeneralTab, { ...props }),
            Form: ({ type }) => {
                return type.renderChild('general');
            },
            hasForm: ({ data }) => data.isVacancy === false,
            save: async (values, view) => {
                const updateData = view.type.getChild('general').getResolvedValue();
                return Promise.resolve({
                    updateValues: await editContractGeneralData(values.id, updateData),
                });
            },
        },
        ancillaryExpenses: {
            name: translate('contract.details.ancillaryExpenses'),
            Content: (props) => _jsx(AncillaryExpensesTab, { ...props }),
            Form: ({ type }) => {
                return type.renderChild('ancillaryExpense');
            },
            save: async (values, view) => {
                const data = view.type.getChild('ancillaryExpense').getResolvedValue();
                const updateDate = {
                    floating: data.floating.filter(expense => expense.accountId !== undefined),
                    fixed: data.fixed.filter(expense => expense.accountId !== undefined),
                };
                const updatedData = await editContractAncillaryExpensesData(values.id, updateDate);
                return Promise.resolve({
                    updateValues: {
                        ...values,
                        ancillaryExpenses: [...updatedData],
                    }
                });
            },
        },
        persons: {
            name: translate('contract.details.persons'),
            Content: (props) => _jsx(PersonsTab, { ...props }),
            Form: ({ type }) => {
                return type.renderChild('persons');
            },
            save: async (values, view) => {
                const data = view.type.getChild('persons').getResolvedValue();
                const updateData = {
                    residents: data.residents.filter(resident => resident.personId !== undefined),
                    contractParties: data.contractParties.filter(contractParty => contractParty.personId !== undefined)
                };
                return Promise.resolve({
                    updateValues: await editContractPersonsData(values.id, updateData)
                });
            },
        },
        deposit: {
            name: translate('contract.details.deposit'),
            Content: (props) => _jsx(DepositTab, { ...props }),
            Form: ({ type }) => type.renderChild('deposit'),
            save: async (values, view) => {
                const data = view.type.getChild('deposit').getResolvedValue();
                const updatedValues = await editContractDepositData(values.id, data);
                return Promise.resolve({
                    updateValues: {
                        ...updatedValues,
                        depositBankAccountId: updatedValues.depositBankAccount?.id
                    }
                });
            },
        },
        documents: {
            name: translate('contract.details.documents'),
            showHeader: false,
            Content: (props) => _jsx(DocumentsTab, { ...props }),
        },
        uploads: {
            name: translate('contract.details.uploads'),
            showHeader: false,
            Content: (props) => _jsx(UploadsTab, { ...props }),
        },
    };
    return Object.fromEntries(Object.entries(sections).filter(([key, value]) => value !== undefined));
});
