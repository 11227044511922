import { jsx as _jsx } from "react/jsx-runtime";
import { BaseFormTypeOld } from '@f2w/form-new';
import { SummaryStepTemplate } from './SummaryStepTemplate';
export class SummaryStep extends BaseFormTypeOld {
    buildFields() {
        return {};
    }
    _renderWidget(props) {
        return _jsx(SummaryStepTemplate, { ...props });
    }
}
