import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from "react";
import { Button, ButtonToolbar, FlexCol, FlexRow, Icon, Text } from "Components/Atoms";
import { translate } from "Services/Translator";
import { SaveCalled } from "Pages/AncillaryExpense";
import { CalculationDistributionConsumptionTable, CalculationDistributionFixedTable } from "../../../table";
import { DataTable } from "@f2w/data-table";
import { FixCostModal } from "./FixCostModal";
import { formatMoney } from "Services/NumberHelper";
export const ConsumptionDistributionTable = ({ consumptionTableRef, isViewMode, accountDistributions, loadData, overviewData, setEditMode, calculationId, saveMeters, updateFixCostDistributionAmount, saveTables }) => {
    const consumptionAccount = accountDistributions.consumption;
    const commonAreaAccount = accountDistributions.common_area;
    const fixedCostAccount = accountDistributions.fixed_cost;
    const [fixCostModalOpen, setFixCostModalOpen] = React.useState(false);
    const [hasCommonArea, commonAreaPositions, commonAreaAmount] = React.useMemo(() => {
        return [
            !!commonAreaAccount,
            commonAreaAccount?.positions ?? [],
            commonAreaAccount?.totalCost ?? 0.0
        ];
    }, [commonAreaAccount]);
    const [hasFixCost, fixCostAmount, fixCostPositions,] = React.useMemo(() => {
        return [
            !!fixedCostAccount,
            fixedCostAccount?.totalCost ?? 0.0,
            fixedCostAccount?.positions ?? [],
        ];
    }, [fixedCostAccount]);
    const consumptionTable = CalculationDistributionConsumptionTable.use({
        loadData,
        overviewData,
        data: consumptionAccount?.positions ?? [],
        calculationId,
        saveMeters,
        ancillaryAccountId: accountDistributions.accountId
    });
    consumptionTableRef.current = consumptionTable;
    const fixCostTable = CalculationDistributionFixedTable.use({
        loadData,
        overviewData,
        distributionAccount: fixedCostAccount,
        data: fixCostPositions,
        type: 'fixed_cost'
    });
    const commonAreaTable = CalculationDistributionFixedTable.use({
        loadData,
        overviewData,
        distributionAccount: fixedCostAccount,
        data: commonAreaPositions,
        type: 'common_area'
    });
    return (_jsxs(_Fragment, { children: [_jsxs(FlexRow, { h: 50, gap: 20, alignCenter: true, pt: 20, justifySpaceBetween: true, children: [_jsxs(FlexRow, { gap: 10, alignCenter: true, children: [_jsx(Text, { style: { margin: 0 }, children: translate('consumptionTable.title') }), _jsx(Text, { primary: true, children: formatMoney(consumptionAccount.totalCost) })] }), !isViewMode && (consumptionTable.editMode
                        ? (_jsxs(ButtonToolbar, { children: [_jsx(Button, { color: 'error', variant: 'outline', style: { border: 'none' }, onClick: () => {
                                        consumptionTable.updateApi.resetAll();
                                        consumptionTable.setEditMode(false);
                                        setEditMode(false);
                                    }, children: translate('btn.cancel') }), _jsx(Button, { onClick: async () => {
                                        await consumptionTable.onSave(consumptionTable);
                                        await saveTables(SaveCalled.SAVE_DISTRIBUTIONS);
                                        setEditMode(false);
                                    }, children: translate('btn.save') })] }))
                        : (_jsx(ButtonToolbar, { children: _jsx(Button, { onClick: () => {
                                    consumptionTable.setEditMode(true);
                                    setEditMode(true);
                                }, children: translate('btn.edit') }) })))] }), _jsx(DataTable.SimpleTable, { filter: 'inline', instance: consumptionTable }), hasFixCost && (_jsxs(_Fragment, { children: [_jsx(FlexRow, { justifySpaceBetween: true, alignCenter: true, children: _jsxs(FlexRow, { gap: 10, alignCenter: true, children: [_jsxs(Text, { style: { margin: 0 }, children: [translate('consumptionTable.fixCost.title'), ": ", fixedCostAccount?.propertyDistributionKeyName] }), _jsx(Text, { primary: true, children: formatMoney(fixCostAmount) }), !isViewMode && _jsx(Button, { style: { border: 'none', padding: 0 }, variant: 'outline', onClick: () => setFixCostModalOpen(true), children: _jsx(Icon, { icon: 'edit' }) })] }) }), _jsx(DataTable.SimpleTable, { filter: 'inline', instance: fixCostTable })] })), hasCommonArea && (_jsxs(FlexCol, { gap: 20, pt: 20, children: [_jsx(FlexRow, { justifySpaceBetween: true, alignCenter: true, children: _jsxs(FlexRow, { gap: 10, alignCenter: true, children: [_jsxs(Text, { style: { margin: 0 }, children: [translate('consumptionTable.commonArea'), ": ", commonAreaAccount?.propertyDistributionKeyName] }), _jsx(Text, { primary: true, children: formatMoney(commonAreaAmount) })] }) }), _jsx(DataTable.SimpleTable, { filter: 'inline', instance: commonAreaTable })] })), fixCostModalOpen && (_jsx(FixCostModal, { distributionAmount: consumptionAccount.totalCost + consumptionAccount.distributableFixedCost, previousAmount: fixCostAmount, onClose: () => {
                    setFixCostModalOpen(false);
                    consumptionTable.refresh();
                }, saveTables: saveTables, accountId: accountDistributions.accountId, calculationId: calculationId, updateFixCostDistributionAmount: updateFixCostDistributionAmount }))] }));
};
