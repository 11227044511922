export var ProcessState;
(function (ProcessState) {
    ProcessState["CREATED"] = "created";
    ProcessState["DRAFT"] = "draft";
    ProcessState["DOCUMENTS_SENT"] = "documents_sent";
    ProcessState["DOCUMENTS_RECEIVED"] = "documents_received";
    ProcessState["COMPLETED"] = "completed";
    ProcessState["CANCELED"] = "cancelled";
})(ProcessState || (ProcessState = {}));
export var ProcessType;
(function (ProcessType) {
    ProcessType["ONBOARDING"] = "onboarding";
    ProcessType["CONTRACT_RENT_MODIFICATION"] = "contractRentModification";
})(ProcessType || (ProcessType = {}));
