import { createWizardType } from '@f2w/view';
import Portfolio from 'Models/Portfolio';
import { translate } from 'Services/App';
import { isNumLike, toNum } from '@f2w/utils';
import noticeServices from './contractNoticeServices';
import { ContractNoticeWizardType } from './ContractNoticeWizardType';
import { generate } from 'Services/Router';
export const useContractNoticeWizard = () => {
    const getScopeFromContract = (contractId) => {
        const portfolio = Portfolio.instance();
        const contract = isNumLike(contractId) && portfolio.getContractById(toNum(contractId));
        if (contract) {
            return {
                propertyId: contract.propertyId,
                buildingId: contract.buildingId,
                rentalObjectId: contract.rentalObjectId,
                contractId: contract.id,
            };
        }
        return portfolio.getScopeData();
    };
    const save = async (valueType) => {
        const root = ContractNoticeWizardType.getRoot(valueType);
        const data = root.getResolvedValue();
        const selectedSubContractIds = root
            .children
            .terminationStep
            .children
            .contracts
            .mapSelected(v => v.value.id);
        const saveData = {
            dateOfNotice: data.noticeStep.dateOfNotice,
            noticePer: data.noticeStep.noticePer,
            terminatedBy: data.noticeStep.noticeBy,
            selectedSubContractIds
        };
        return noticeServices.save(data.noticeStep.contractId, saveData);
    };
    const cfg = createWizardType({
        id: 'contractNotice',
        path: 'contract-notice/:id',
        labels: {
            save: translate('contractNotice.saveAction')
        },
        title: () => translate('contractNotice.wizardTitle'),
        load: async ({ params }) => {
            const stepData = {
                noticeStep: {
                    setting: {},
                    ...getScopeFromContract(params.id)
                },
                terminationStep: {}
            };
            return {
                id: params.id,
                stepData
            };
        },
        save: async (values, stepApi) => {
            const props = await save(stepApi.valueType);
            const contractId = stepApi.valueType.children.noticeStep.value.contractId;
            window.location.href = generate('contracts.overview', { contractId });
            return props;
        },
        onClose: ({ navigate }) => {
            window.location.href = generate('contracts.overview');
        },
        steps: {
            noticeStep: {
                title: () => translate('contractNotice.noticeStep.title'),
                type: () => new ContractNoticeWizardType.NoticeStep({ hideLabel: true }),
            },
            terminationStep: {
                title: () => translate('contractNotice.terminationStep.title'),
                type: () => new ContractNoticeWizardType.TerminationStep({ hideLabel: true }),
            },
            summaryStep: {
                title: () => translate('contractNotice.summaryStep.title'),
                type: () => new ContractNoticeWizardType.SummaryStep({ hideLabel: true }),
            },
        },
    })({});
    return cfg;
};
